<template>
<div id="id">
    <Navbar/>
  <div class="home mt-0 mt-md-0 mt-lg-5">
    <ButtonWhatsapp/>
      <div class="container-fluid pt-4 pt-md-0 pt-lg-0  px-0 mx-0" style="background-color: #FFFDEE">
        <div id="carouselExampleControls" class="carousel slide mx-0 px-0" data-bs-ride="carousel">
          <div class="carousel-inner">
            <div class="carousel-item active">
              <img src="../assets/images/Banner Web Baper 1366x653px-Juni-01.jpg" class="d-block w-100" alt="...">
            </div>
            <div class="carousel-item">
              <img src="../assets/images/WhatsApp Image 2021-12-30 at 08.02 1.png" class="d-block w-100" alt="...">
            </div>
            
          </div>
          <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="prev">
            <span class="carousel-control-prev-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Previous</span>
          </button>
          <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleControls" data-bs-slide="next">
            <span class="carousel-control-next-icon" aria-hidden="true"></span>
            <span class="visually-hidden">Next</span>
          </button>
        </div>
      </div>
      <div class="container-fluid py-3 py-lg-5 px-0 mx-0" style="background-color: #FFFDEE">
        <div class="container px-4  px-md-4 px-lg-0 mt-5">
          <div class="row d-flex justify-content-center pb-3 pb-lg-5">
            <div class="col-12 col-md-12 col-lg-7 mb-3 mb-md-3 mb-lg-0">
              <hr  style="border-top: 4px solid #B28F36; width: 40px; opacity:100">
                <h3 class="tcontittle mb-lg-3 mb-0">Keunggulan tinggal <br>di Estusae Alam Indah Cikopo</h3>
                <ul type="none" class="tlist mx-0 px-0" style="line-height: 29px">
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> Hunian Premium seluas 24 Ha</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> Akses Terbaik : Pusat Industri Cikampek & Cikarang</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> Area Hijau Terkoneksi dengan Garden Concept</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> Internet by Indihome</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> Design Modern</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> Nilai Investasi Tinggi di Masa Depan</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> Konsep Rumah Tumbuh</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> ROW Jalan Lebar</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> 200+ KK Hunian Aktif</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> Jogging Track</li>
                  <li><img src="../assets/images/circle-check-regular 4.png" alt=""> Swimming Pool</li>
                </ul>
            </div>
           
            <div class="col-lg-5 col-12 col-md-12 pb-5 pt-4 px-4 " style="background : #A8A068; border-radius: 15px;">
              <div class="row">
                <div class="col-12 py-0">
                  <hr class=""  style="border-top: 4px solid #2E2D2D; width: 40px; opacity:100">
                  <h1 style="font-family: Maintree; color: #FFFFFF">Ingin Bermitra dengan Estusae? </h1>
                </div>
              </div>
              <div class="row py-2">
                  <div class="col-12">
                    <span class="formisi" >Silahkan isi data dibawah ini untuk bermitra dengan kami</span>
                  </div>
              </div>
              <div class="row">
                <div class="col-12">

                  <div class="form-floating mb-3 tinput">
                    <input type="text" required="" class="form-control" v-model="name"  placeholder="Nama Lengkap">
                    <label for="floatingInput">Nama Lengkap</label>
                  </div>
                  <div class="form-floating mb-3 tinput">
                    <input type="text" class="form-control" v-model="kantor_agent" placeholder="Nama Kantor Agent">
                    <label for="floatingInput">Nama Kantor Agent</label>
                  </div>
                  <div class="form-floating mb-3 tinput">
                    <input type="number" required class="form-control" v-model="hp" placeholder="Nomer Handphone" >
                     <small v-if="infoError" style="color:red;">{{infoError}}</small>
                    <label for="floatingInput">Nomer Handphone</label>
                  </div>
                  <div class="form-floating mb-3 tinput">
                    <input type="email" class="form-control" v-model="email" placeholder="Alamat Email">
                    <label for="floatingInput">Alamat Email</label>
                  </div>
                  
                  <!-- <div class="form-floating  mb-3 tinput">
                      
                      <select  class=" form-select " aria-label="Default select example" v-model="Message">
                        <option value="900 Ribu">900 Ribu</option>
                        <option value="1,2 - 1,5 Juta">1,2 - 1,5 Juta</option>
                        <option value="1,5 - 2,5 Juta">1,5 - 2,5 Juta</option>
                        <option value="> 2,5 Juta">> 2,5 Juta</option>
                      </select> 
                      <label for="floatingInput"><strong>Pilihan Cicilan Per-Bulan</strong> </label>
                  </div> -->
                  
                    <button id="getpromo" class=" w-100 btn btn-lg kirim btn-form-brosur" style="background-color: #0F8C1C" @click="HandleSubmit()">Kirim</button> 
                    <div v-if="formSuccess" class="alert alert-success mt-3 mb-3" role="alert">
                        {{formSuccess}}
                    </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        </div>

  <HomeResidential/>
  <Area/>  
  <HomeLocation/>
  <!-- <HomeNew/> -->
  <div class="container-fluid py-2" style="background-color: #2E2D2D">
    </div>
    <div class="containerfluid  py-5 " style="background-color: #FFFDEE">
    <div class="container">
      <div class="row pt-3">
        <div class="col-12">
          <h2 class="hubungi text-center">SEGERA HUBUNGI KAMI!</h2>
        </div>
      </div>
      <div class="row py-4">
        <!-- <div v-if="NamaSales" class="col-12 text-center">
         <a data-bs-toggle="modal" class="d-flex justify-content-center btn-wa-footer" data-bs-target="#exampleModal"><img src="../assets/images/WA-atas.png" class="wa2" alt=""></a>
        </div> -->
        <div class="col-12 text-center">
         <a data-bs-toggle="modal" class="d-flex justify-content-center btn-wa-footer" data-bs-target="#exampleModal1"><img src="../assets/images/WA-atas.png" class="wa2" alt=""></a>
        </div>
      </div>
    </div>
    </div>
    <div class="container-fluid pt-5" style="background-color: #2E2D2D">
        <div class="container">
            <div class="row">
                <div class="col-12 col-lg-1"></div>
                <div class="col-12 col-lg-4 my-4 text-lg-start text-center">
                    <img src="../assets/images/Logo-TRANSPARAN WHITE 1.png" class="my-5 logoFooter" alt="">
                </div>
                <div class="col-12 col-lg-2">

                </div>
                <div class="col-12 col-lg-4">
                    <div class="row">
                        <div class="col-12 contact text-center text-lg-start">
                            <h3>CONTACT US</h3>
                        </div>
                    </div>
                    <!-- <div v-if="NamaSales" class="row py-3 d-flex justify-content-center d-flex justify-content-lg-start">
                        <div class="col-6 col-md-6 col-lg-6 text-center justify-content-center img-user">
                            <img :src="'https://api.makutapro.id/storage/photo/'+PhotoUser" alt="" class=" img-fluid img-user" width="100%">
                        </div> 
                        <div class="col-8 col-md-4 col-lg-6 text-center mt-4">
                            <span class="text-light"><b>{{NamaSales}}</b>
                            <br><h6>Sales Representatif</h6></span>
                            
                            <a data-bs-toggle="modal" data-bs-target="#exampleModal">
                                <img src="../assets/images/Group 22.svg"  >
                            </a>
                            
                        </div>   
                    </div>          -->
                    <div class="row py-3 d-flex justify-content-center d-flex justify-content-lg-start">
                        <div class="col-12 col-lg-12 mb-4 mb-lg-0 d-flex justify-content-center d-flex justify-content-lg-start">
                            <a data-bs-toggle="modal" class="d-flex justify-content-center d-flex justify-content-lg-start d-flex justify-content-md-center btn-wa-footer" data-bs-target="#exampleModal1">
                                <img src="../assets/images/Group 22.svg" class="wa" alt="">
                            </a>
                        </div>
                    </div>
                    <div class="row pt-3 d-flex justify-content-center d-flex justify-content-lg-start">
                        <div class="col-6">
                            <div class="row">
                                <div class="col-2 col-lg-12"></div>
                                <div class="col-4 col-lg-6 mb-3 mb-lg-0 ">
                                    <a href="https://www.instagram.com/estusae_official/"><img src="../assets/images/instagram-01 1.png" class="ig" alt=""></a>
                                </div>
                                <div class="col-4 col-lg-6 mb-3 mb-lg-0">
                                    <img src="../assets/images/facebook-circular-logo-01 1.png" class="fb" alt="">
                                </div>
                                 <div class="col-2 col-lg-12"></div>
                            </div>
                        </div>  
                    </div>
                    <div class="row pt-3">
                        <div class="col-12 col-lg-6 text-center text-lg-start mb-4 mb-lg-0">
                            <h6 class="by">Developed By :</h6>
                            <a href=""><img class="logoAkhir"  src="../assets/images/Logo develop2-01-01.png" alt=""></a>
                        </div>
                    </div>
                </div>
                <div class="col-12 col-lg-1"></div>
            </div>
        </div>
        <div class="container text-center">
            <div class="row pb-2 pt-2 pt-lg-5">
                <div class="col-12 copy">
                    <span>© Copyright All Right Reserved. 2021</span>
                </div>
            </div>
        </div>
    </div>
        <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" style="color : #2E2D2D;" id="exampleModalLabel">Ingin Bermitra dengan Estusae? 
                        <h6><p class="mt-1">Silahkan isi data dibawah ini untuk bermitra dengan kami</p></h6></h5>
                        
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form>
                            <div class="mb-3">
                                <input type="text" class="form-control" v-model="name"  placeholder="Nama Lengkap">
                            </div>
                            <div class="mb-3">
                                <input type="text" class="form-control"  v-model="kantor_agent" placeholder="Nama Kantor Agent">
                            </div>
                            <div class="mb-3">
                                <input type="number" placeholder="Phone, contoh: 0812800xxxxx" class="form-control" id="message-text" v-model="hp" >
                                <small v-if="infoError" style="color:red;">{{infoError}}</small>
                            </div>
                            <div class="mb-3">
                                <input type="email" class="form-control"  v-model="email" placeholder="Email">
                            </div>
                            
                        </form>
                    </div>
                    <div class="d-grid gap-2 mb-3 mx-3 text-center">
                        <button class="btn btn-success btn-submit-form-brosur" style="background : #2E2D2D; border-color : #2E2D2D;" type="submit" @click="HandleSubmit2()">SUBMIT</button>
                    </div>                        
                    <div v-if="infoSuccess" class="alert alert-success mt-1 mb-3 mx-3" role="alert">
                    {{infoSuccess}}
                    </div>
                </div>
            </div>
        </div>
        <div class="modal fade" id="exampleModal1" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
            <div class="modal-dialog">
                <div class="modal-content">
                    <div class="modal-header">
                        <h5 class="modal-title" style="color : #2E2D2D;" id="exampleModalLabel">Ingin Bermitra dengan Estusae?
                        <h6><p class="mt-1">Silahkan isi data dibawah ini untuk bermitra dengan kami</p></h6></h5>
                        <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                    </div>
                    <div class="modal-body">
                        <form>
                             <div class="mb-3">
                                <input type="text" class="form-control" v-model="name"  placeholder="Nama Lengkap">
                            </div>
                            <div class="mb-3">
                                <input type="text" class="form-control"  v-model="kantor_agent" placeholder="Nama Kantor Agent">
                            </div>
                            <div class="mb-3">
                                <input type="number" placeholder="Phone, contoh: 0812800xxxxx" class="form-control" id="message-text" v-model="hp" >
                                <small v-if="infoError" style="color:red;">{{infoError}}</small>
                            </div>
                            <div class="mb-3">
                                <input type="email" class="form-control"  v-model="email" placeholder="Email">
                            </div>
                           
                        </form>
                    </div>
                    <div class="d-grid gap-2 mb-3 mx-3 text-center">
                        <button class="btn btn-success btn-submit-form-brosur" style="background : #2E2D2D; border-color : #2E2D2D;" type="submit" @click="HandleSubmit2()">SUBMIT</button>
                    </div>                        
                    <div v-if="infoSuccess" class="alert alert-success mt-1 mb-3 mx-3" role="alert">
                    {{infoSuccess}}
                    </div>
                </div>
            </div>
        </div>
  <!-- <Footer/> -->
</div>
</div>
</template>

<script>

// import HomeNew from '../components/HomeNew.vue'
import Area from '../components/Area.vue'
import HomeLocation from '../components/HomeLocation.vue'
import ButtonWhatsapp from '../components/ButtonWhatsapp.vue'
import Navbar from '../components/Navbar.vue'

import HomeResidential from '../components/HomeResidential.vue'
// import Footer from '../components/Footer.vue'
let urlWeb = document.URL;
let url = new URL(urlWeb);
let utm_source = url.searchParams.get('utm_source')
let utm_campaign = url.searchParams.get('utm_campaign')
let utm_medium = url.searchParams.get('utm_medium')

export default {
  components: { Area, HomeLocation, ButtonWhatsapp, Navbar,
   HomeResidential,},
  name: 'Home',
  mounted () {
    window.scrollTo(0, 0)
  },
  // props: ['UsernameKP', 'WA'],
  data(){
        return{
            name : '',
            email : '',
            hp : '',
            kantor_agent : '',
            message : '',
            infoSuccess : '',
            formSuccess : '',
            infoError : ''          
        }
  },
  created(){
    this.utm_source = utm_source;
    this.utm_medium = utm_medium;
    this.utm_campaign = utm_campaign;
    // let config = {
    //       headers : {
    //         'Access-Control-Allow-Origin' : '*',
    //         'Content-type' : 'application/json',
    //         'Access-Control-Allow-Methods' : 'GET, POST',
    //         'Access-Control-Allow-Headers' : 'X-Requested-With',
    //         'Set-Cookie' : 'cross-site-cookie=whatever, flavor=choco, SameSite=None, Secure'
    //       },
    //     }
    //   console.log(this.UsernameKP)
    //   this.axios.get("https://api.makutapro.id/sales?UsernameKP="+this.UsernameKP+'&KodeProject=EST', config)
    //     .then(res => (
    //         console.log(res),
    //         this.NamaSales = res.data.data[0].NamaSales,
    //         this.KodeSales = res.data.data[0].KodeSales,
    //         this.HpSales = res.data.data[0].Hp,
    //         this.PhotoUser = res.data.data[0].PhotoUser
            
    //     ))
    //     .catch(err => console.log(err))
  
    },
    methods: {
        WhatsApp(){
             let config = {
                headers : {
                    'Access-Control-Allow-Origin' : '*',
                    'Content-type' : 'application/json',
                    'Access-Control-Allow-Methods' : 'GET, POST',
                    'Access-Control-Allow-Headers' : 'X-Requested-With',
                    'Set-Cookie' : 'cross-site-cookie=whatever, flavor=choco, SameSite=None, Secure'
                },
            }
            let formData = new FormData()
            formData.append('name', this.name)
            formData.append('hp', this.hp)
            formData.append('email', this.email)
            formData.append('kantor_agent', this.kantor_agent)


            this.axios.post('https://adm-estusae.makutapro.id/api/partner', formData, config)
            .then(response=>{
              console.log(response)
                if(response.data.message == "Nomor Handphone Sudah terdaftar"){
                    this.infoError=response.data.message
                }else if(response.data.message == "Nomor Handphone Salah"){
                    this.infoError=response.data.message
                }else{
                  this.name = '',
                  this.email = '',
                  this.hp = '',
                  this.kantor_agent = '',
                  this.formSuccess = 'Terimakasih. Kami akan segera menghubungi Anda',                  
                  window.location.href="https://wa.me/628111696650"
                  // this.infoError = ''
                }
            })
        },
        HandleSubmit(){
             let config = {
                headers : {
                    'Access-Control-Allow-Origin' : '*',
                    'Content-type' : 'application/json',
                    'Access-Control-Allow-Methods' : 'GET, POST',
                    'Access-Control-Allow-Headers' : 'X-Requested-With',
                    'Set-Cookie' : 'cross-site-cookie=whatever, flavor=choco, SameSite=None, Secure'
                },
            }
            let formData = new FormData()
            formData.append('name', this.name)
            formData.append('hp', this.hp)
            formData.append('email', this.email)
            formData.append('kantor_agent', this.kantor_agent)


            this.axios.post('https://adm-estusae.makutapro.id/api/partner', formData, config)
            .then(response=>{
                if(response.data.message == "Nomor Handphone Sudah terdaftar"){
                    this.infoError=response.data.message
                }else if(response.data.message == "Nomor Handphone Salah"){
                    this.infoError=response.data.message
                }else{
                  this.name = '',
                  this.email = '',
                  this.hp = '',
                  this.kantor_agent = '',
                 this.formSuccess = 'Terimakasih. Kami akan segera menghubungi Anda'
                }
            })
        },
        HandleSubmit2(){
             let config = {
                headers : {
                    'Access-Control-Allow-Origin' : '*',
                    'Content-type' : 'application/json',
                    'Access-Control-Allow-Methods' : 'GET, POST',
                    'Access-Control-Allow-Headers' : 'X-Requested-With',
                    'Set-Cookie' : 'cross-site-cookie=whatever, flavor=choco, SameSite=None, Secure'
                },
            }
            let formData = new FormData()
            formData.append('name', this.name)
            formData.append('hp', this.hp)
            formData.append('email', this.email)
            formData.append('kantor_agent', this.kantor_agent)


            this.axios.post('https://adm-estusae.makutapro.id/api/partner', formData, config)
            .then(response=>{
                if(response.data.message == "Nomor Handphone Sudah terdaftar"){
                    this.infoError=response.data.message
                    this.infoError = ''
                }else if(response.data.message == "Nomor Handphone Salah"){
                    this.infoError=response.data.message
                    this.infoError = ''
                }else{
                  this.name = '',
                  this.email = '',
                  this.hp = '',
                  this.kantor_agent = '',
                  window.location.href="https://wa.me/628111696650",
                 this.infoSuccess = 'Terimakasih. Kami akan segera menghubungi Anda'
                }
            })
        }
    }
}
</script>

<style scoped>
.img-user{
  height: 200px;
  width: 200px;
  overflow: hidden;
  /* background-color: #B28F36; */
  border-radius: 50%;
}
.boxx{
width: 100%;
background: #0D424B;
border-radius: 20px;
}

.tinput{
font-family: 'Roboto';
font-style: normal;
font-weight: normal;
font-size: 14px;
line-height: 21px;
letter-spacing: 0.1em;
color: rgba(0, 0, 0, 0.3);
}

.formisi {
  font-family: Roboto;
font-style: normal;
font-weight: normal;

line-height: 21px;
letter-spacing: 0.1em;

color: #FFFFFF;
}

.kirim{
  font-family: Roboto;
font-style: normal;
font-weight: bold;
line-height: 42px;
text-align: center;
letter-spacing: 0.1em;

color: #FFFFFF;

}
.tlist{
  font-family: Roboto;
font-style: normal;
font-weight: normal;
font-size: 20px;
line-height: 33px;
letter-spacing: 0.005em;

color: #727272;

}

.tcontittle{
  font-family: Manuale;
font-style: normal;
font-weight: bold;

text-align: justify;
letter-spacing: 0.06em;

color: #2E2D2D;
}

.box2{
  position: absolute;
width: 574px;
height: 83px;
background: #0F8C1C;
border-radius: 20px;
}

.imgrad{
  width: 100%;
  border-radius: 176px 0px 0px 0px;
}
.hubungi {
    font-family: Manuale;
    font-style: normal;
    font-weight: bold;
    letter-spacing: 0.2em;

    color: #2E2D2D;

}

.logoAkhir{
    width : 130px;
    height : auto;
    
}
.copy {
    font-family: Montserrat;
    font-style: normal;
    font-weight: 500;
    line-height: 39px;
    /* font-size: 19px;

    display: flex;
    align-items: center;
    text-align: center; */
    letter-spacing: 0.055em;

    color: #FFFFFF;
}

.contact {
    font-family: Mansory;
    font-style: normal;
    font-weight: 600;
    line-height: 0px;
    /* or 0% */
    letter-spacing: 0.1em;

    color: #FFFFFF;

}

.by {
    font-family: 'F37Bolton-Regular';
    font-style: normal;

    letter-spacing: 0.035em;

    color: #FFFFFF;

}

.wa{
    width : 160px;
    height : auto;
    
}
.wa2{
    width : 200px;
    height : auto;
    
}
.ig{
    width : 48px;
    height : auto;
    
}
.fb{
    width : 48px;
    height : auto;
    
}
.logoFooter{
    width : 400px;
    height : auto;
    
}
.email{
    width : 160px;
    height : auto;
    
}
.logoAkhir{
    width : 70%;
    height : auto;
    
}
@media only screen and (max-width: 1030px) {
    .imgrad{
        width:100%;
        border-radius: 0px 0px 0px 0px;
    }
    .wa{
            width: 220px;
            height: auto;
    }
    .wa2{
            width: 220px;
            height: auto;
    }
    .logoFooter{
            width: 350px;
            height: auto;
    }
    .ig{
    width : 48px;
    height : auto;
    
    }
    .fb{
        width : 48px;
        height : auto;
        
    }
    .email{
            width: 220px;
            height: auto;
    }
    .logoAkhir{
            width: 40%;
            height: auto;
    }
    
}
@media only screen and (max-width: 700px) {
    .imgrad{
        width: 100%;
        border-radius: 0px 0px 0px 0px;
    }
    .tlist{
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 33px;
    letter-spacing: 0.005em;

    color: #727272;

    }
    .wa{
            width: 200px;
            height: auto;
        }
    .wa2{
            width: 200px;
            height: auto;
        }
        .ig{
    width : 48px;
    height : auto;
    
    }
    .fb{
        width : 48px;
        height : auto;
        
    }
    .logoFooter{
            width: 320px;
            height: auto;
        }
    .email{
            width: 200px;
            height: auto;
        }
    .logoAkhir{
            width: 45%;
            height: auto;
        }
}
</style>